import axios from 'axios';

const api = axios.create({
  baseURL: '/api/'
});

export const getBlogPosts = (params) => api.request({
  method: 'get',
  url: 'blog/',
  params
});

export const getObjects = (type, params) => api.request({
  method: 'get',
  url: `${type}/`,
  params
});

export const getSearch = (params) => api.request({
  method: 'get',
  url: 'search/',
  params
});

export const postOrder = (data, objectId) => api.request({
  method: 'post',
  url: objectId ? `order/${objectId}/` : 'order/',
  data: axios.toFormData(data)
});

export const postShareFriend = (data, lang, objectId) => api.request({
  method: 'post',
  url: `send-pdf/${objectId}/?lang=${lang}`,
  data: axios.toFormData(data)
});

export const postSubscribe = (data, lang) => api.request({
  method: 'post',
  url: `subscribe/?lang=${lang}`,
  data: axios.toFormData(data)
});
